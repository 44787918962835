<template>

  <el-row :gutter='10'>
    <div class="dialogCard">
      <div class="subBox">
        <div class="title">今日实时数据</div>
        <div class="subTitle">更新时间：{{nowTime}}</div>
      </div>

      <el-row style="width:100%;">
        <el-col :lg="6" :md="12" :sm="12" :xl="6" :xs="24" class="ul" v-for="(item, index) in realData" :key="index">
          <template v-if="!item.hide">
            <p class='smTitle'>{{item.name}}</p>
            <div v-if="loading">加载中...</div>
            <template v-else>
              <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
            </template>
          </template>
        </el-col>
      </el-row>
    </div>
  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    nowTime: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    watch(
      () => props.data,
      (newVal, oldVal) => {
        state.realData[0].countConfig.endVal = newVal.todayPageVisitTimes
        state.realData[1].countConfig.endVal = newVal.todayUserVisitNum
        state.realData[2].countConfig.endVal = newVal.todayMeetingSignUpNum
        state.realData[3].countConfig.endVal = newVal.todayMeetingSignInNum

        if (props.meetingType != 'EXHIBITION') {
          state.realData[3].hide = true
        }
        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      type: '',
      realData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '访问页面数',
          colCount: 8,
          hide: false,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '访问人数',
          colCount: 8,
          hide: false,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '注册人数',
          colCount: 8,
          hide: false,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '签到人数',
          colCount: 8,
          hide: false,
        },
      ],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }

  .ul {
    margin-top: 15px;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>